import React, { useEffect, useState } from "react";
import axios from "axios";

const EnableFlight = () => {
  const [user, setUser] = useState([]);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}enableflightInformation`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response?.data?.message == "No inactive flights found.") {
          setMsg(response?.data?.message);
          return;
        }
        setUser(response.data.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleChange = async (locked, id) => {
    const confirmChange = window.confirm(
      "Are you sure you want to activate this flight ?"
    );
    if (confirmChange) {
      let active = locked === 1 ? 0 : 1;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}enableflightInformation/${id}`,
          { active },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        alert("Successfully activated the flight.");
        window.location.reload();
      } catch (error) {
        alert("Unable to activate the flight.");
      }
    } else {
      return;
    }
  };
  const handleDelete = async (id) => {
    const confirmChange = window.confirm(
      "Are you sure you want to delete this flight ?"
    );
    if (confirmChange) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}delete-flight/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        alert("Successfully deleted the flight.");
        window.location.reload();
      } catch (error) {
        alert("Unable to delete the flight.");
      }
    } else {
      return;
    }
  };

  return (
    <div>
      <div className="container mt-3">
        <h2>Review Flights</h2>
        <div className="card bg-light">
          <div className="card-body">
            <div className="container mt-3">
              {msg ? (
                msg
              ) : (
                <div className="card bg-light">
                  <div className="card-body overflow-auto">
                    <table className="table table-striped overflow-auto">
                      <thead>
                        <tr>
                          <th>SNo.</th>
                          <th>Flight Code</th>
                          <th>Airline Name</th>
                          <th>From Airport</th>
                          <th>To Airport</th>
                          <th>Action</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.flight_code}</td>
                            <td>{item?.airline_name}</td>
                            <td>{item?.from_airport_name}</td>
                            <td>{item?.to_airport_name}</td>
                            <td
                              onClick={() =>
                                handleChange(item?.active, item?.flight_id)
                              }
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            >
                              <td>{item?.active === 0 ? "Activate" : ""}</td>
                            </td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => handleDelete(item?.flight_id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnableFlight;
